import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

const WorksPage = ({ data }) => {
  const works = data.allContentfulCaseStudy.nodes;
  const title = '制作実績一覧｜奈良のホームページ制作なら｜インヴォルブ';
  const description =
    '当事務所が手がけた ウェブサイト制作・ウェブシステム開発・動画制作 の実績をご紹介しています。クライアントの課題に対して、どのようなアプローチで解決したのか、具体的な事例とともに掲載しております。ぜひご覧ください。';
  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    {
      name: '制作実績一覧',
      url: 'https://www.involve-in.jp/works',
    },
  ];

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.involve-in.jp/works/" />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.involve-in.jp/works" />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>
      <section className="max-w-screen-2xl w-full mx-auto py-32 px-20 bg-white bg-opacity-95 shadow-2xl rounded-xl">
        {/* タイトル */}
        <h1 className="font-roboto text-7xl md:text-[12rem] font-bold text-center mb-16 text-black">
          Works
        </h1>
        <h2 className="text-3xl font-bold mb-12 text-center">制作実績</h2>
        {/* 事例一覧（2列レイアウト） */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {works.map((work) => (
            <Link
              to={`/works/${work.slug}`}
              key={work.slug}
              className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
            >
              {/* 画像 */}
              <div className="w-full aspect-[16/9] bg-gray-100 flex items-center justify-center">
                {work.image && work.image.length > 0 && (
                  <GatsbyImage
                    image={getImage(work.image[0])}
                    alt={work.title}
                    className="w-full h-auto"
                  />
                )}
              </div>
              {/* タイトル */}
              <div className="p-6 text-center bg-black text-white">
                <h2 className="text-2xl font-semibold">{work.title}</h2>
              </div>
            </Link>
          ))}
        </div>
      </section>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 600, placeholder: BLURRED)
        }
      }
    }
  }
`;

export default WorksPage;
